/**
 *
 * @module SlideXAutoplay *
 * @author Kai Wieland <kai.wieland@werbewelt.de>
*/

import { SlideXEventManager } from "./SlideXEventManager";
import { SlideXTimer } from "./SlideXTimer";

/**
 * SlideXAutoplay handles the option to auto slide the slider
 */
 class SlideXAutoplay {

    /**
     * @private
     * @type {HTMLElement}
     */
    _moduleWrapper = null;

    /**
     * @private
     *@type {KeenSlider}
     */
    _slider = null;

    /**
     * @private
     * @type {SlideXTimer}
     */
    _timer = null;

    /**
     * @private
     * @type {Boolean}
     */
    _mouseOver = false;

    /**
     * @private
     * @type {SlideXEventManager}
     */
     _events = false;


    /**
     * @param {HTMLElement} moduleWrapper
     * @param {SlideXEventManager} events
     */
    constructor(moduleWrapper, events){

        this._moduleWrapper = moduleWrapper;

        this._events = events;

    }

    /**
     * @property {Function} init this function fires the init process of this class
     * @param {KeenSlider} slider
     * @returns {void}
     */
    init(slider){

        this._slider = slider;

        this._slider.on("created", ()=>{this._createdHandler();});

        this._slider.on("dragStarted", ()=>{this._stopTimer();});

        this._slider.on("animationStarted", ()=>{this._stopTimer();});

        window.addEventListener("resize", ()=>{this._resizeHandler();});

        this._slider.on("updated", ()=>{this._restartTimer();});

        this._timer = new SlideXTimer(()=>{this._onUpdateHandler();}, this._slider.options.autoplay);


    }

    /**
     * @private
     * @property {Function} _createdHandler this function ist fired when the slider fires the created event
     * @returns {void}
     */
    _createdHandler(){

        console.log("--------------------");
        console.log("_createdHandler");
        console.log(this._moduleWrapper);

        this._moduleWrapper.addEventListener("mouseover", () => {
            this._events.dispatch('enter');
            this._mouseOver = true
            this._stopTimer();
        });
        this._moduleWrapper.addEventListener("mouseout", () => {
            this._events.dispatch('out');
            this._mouseOver = false
            this._restartTimer();
        });
        this._restartTimer();
    }

    /**
     * @private
     * @property {Function} _stopTimer this function stops the timer
     * @returns {void}
     */
    _stopTimer(){

        this._timer.stop();
    }

    /**
     * @private
     * @property {Function} _restartTimer this function restarts the timer
     * @returns {void}
     */
    _restartTimer(){

        this._timer.stop();
        this._timer.start();
    }

    /**
     * @private
     * @property {Function} _onUpdateHandler  this function ist fired when the slider fires the update event
     * @returns {void}
     */
    _onUpdateHandler(){

        this._slider.next();
    }

    /**
     * @private
     * @property {Function} _resizeHandler  this function ist fired when the window gets resized
     * @returns {void}
     */
    _resizeHandler(){

        this._timer.stop();

    }

    /**
     * @property {Function} start  this function restarts the timer
     * @returns {void}
     */
    start(){

        this._restartTimer();
    }

    /**
     * @property {Function} start  this function stops the timer
     * @returns {void}
     */
    stop(){

        this._stopTimer();
    }

    // GETTER SETTER

}

export {SlideXAutoplay};